import React from "react"
import Layout from "../templates/layout"
import Topography from '../components/topography-section'
import Hero from '../components/hero'
import Container from '../components/container'
import Card from '../components/service-card'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const GuideServices = ({ data }) => {

  const services = data.allPrismicService.nodes


  

  return (
    <Layout
      title="Our Guide Services | Loveless Outdoor Adventures"
      description="Loveless Outdoor Adventures offers guide services on Kentucky Lake for bowfishing, catfishing, noodling, and duck hunting. Which adventure are you up for?"
    >
        <Hero title="our guide services" tagline="what adventure are you waiting on?" />
        <Topography>
            <Container>
                
                { 
                  services !== null && 
                    services.map(({ data, uid }) => {
                    const price = new Intl.NumberFormat({ style: 'currency', currency: 'USD' }).format(data.price);
                    const link = `/guide-services/${uid}`
                    const image = getImage(data.service_image)
                    console.log(data.service_image.gatsbyImageData)
                    return (
                      <Card
                        key={uid}
                        title={data.service_name.text}
                        overview={data.service_overview.text}
                        link={link}
                        price={'$' + price}
                        priceUnit={data.pricing_unit}
                      >
                        <GatsbyImage image={image} alt="Guide Service" />
                      </Card>
                    )
                  })
                }
                
            </Container>
        </Topography>
    </Layout>
  )
}




export default GuideServices

export const query = graphql`
query {
  allPrismicService(sort: {fields: id}) {
    nodes {
      data {
        service_name {
          text
        }
        service_overview {
          text
        }
        price
        pricing_unit
        service_image {
          alt
          gatsbyImageData 
        }
        faq {
          faq_answer {
            text
          }
          faq_question {
            text
          }
        }
      }
      uid
    }
  }
}
`