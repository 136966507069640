import React from 'react'
import styled from "styled-components";
// import Img from "gatsby-image/withIEPolyfill"
import H2 from '../components/typography/h2'
import Button from '../components/button'
import P from '../components/typography/p'

const Card = ({ children, title, link, overview, price, priceUnit }) => {
    return (
        <Block>
            <ServiceImage>
                {children}
            </ServiceImage>
            <Copy>
                <Header2>{title}</Header2>
                <P>{overview}</P>
                <Price>Packages Starting at {price} {priceUnit}</Price>
                <Button link={link} buttonLabel={"Learn More"} />
            </Copy>
        </Block>
    );
};

const Block = styled.div`
    background-color: white;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, .10);
    max-width: 100%;
    padding: 1.5em;
    border-radius: 15px;
    margin-bottom: 3em;
    @media (min-width: 800px) {
        display: flex;
    }    
`

const Price = styled.p`
    font-family: 'Teko';
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 500;
`

const Copy = styled.div`
    @media (min-width: 800px) {
        width: calc(100% - 30% - 2em);
        margin: 0 0 0 2em;
    }
`

const Header2 = styled(H2)`
    margin: 0 !important;
`

const ServiceImage = styled.div`
    @media (min-width: 800px) {
        width: calc(100% - 70%);
    }
`



export default Card;